import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ExcelApi from "../../../api/excel.api";
// import MemberApi from "../../../api/memebr.api";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import ReviewModal from "./reviewModal";
import ReviewApi from "../../../api/review.api";
import ExcelApi from "../../../api/excel.api";

const Review = () => {
  const { t } = useTranslation();

  // --------------------------------- //
  // ---------- 더미 데이터 ---------- //
  // ------------------------------- //
  const dummyData = [
    {
      id: 1,
      email: "이메일이메일이메일이메일", // 이메일
      name: "이름이름이름이름이름", // 이름
      leaveDate: "YYYY/MM/DD", // 탈퇴일
      leaveReason: "탈퇴사유탈퇴사유탈퇴사유탈퇴사유탈퇴사유탈퇴사유", // 탈퇴사유
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getReviewList();
  }, [location]);

  const [reviewList, setReviewList] = useState([1]);
  const [keyword, setKeyword] = useState("");

  const getReviewList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await ReviewApi.getList(data)).data.data;
      setReviewList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [filterOpen, setFilterOpen] = useState(-1);

  const isAllCheck = useMemo(() => {
    const checkedList = reviewList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === reviewList.length;
  }, [reviewList]);

  const [title, setTitle] = useState(`${t("review.title")}`);
  const [subTitle, setSubTitle] = useState(`${t("review.reviewCount")}`);

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await ReviewApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getReviewList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: t("review.email"), key: "email", width: 40 },
      { header: t("review.rating"), key: "score", width: 40 },
      { header: t("review.createdAt"), key: "createdAt", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("review.title")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetReviewExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          score: item.score?.toFixed(1),
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = reviewList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetReviewExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          score: item.score?.toFixed(1),
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  const [selectedItem, setSelectedItem] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{t("review.title")}</TableTitle>
          <TableTop>
            <TableCount text={t("review.reviewCount")} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.review}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.review}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width="90px" />
              {/* 이메일 */}
              <col width="253px" />
              {/* 내용 */}
              <col width="333px" />
              {/* 별점 */}
              <col width="78px" />
              {/* 작성일 */}
              <col width="130px" />
              {/* 삭제하기 */}
              <col width="82px" />
              {/* 상세보기 */}
              <col width="78px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setReviewList(
                          reviewList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setReviewList(
                          reviewList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("review.email")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("review.content")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("review.rating")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("review.createdAt")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("review.del")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("review.more")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {reviewList.map((item, index) => {
                return (
                  <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setReviewList([...reviewList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText>{item.email}</TableText>
                    </Td>
                    <Td>
                      <TableText style={{ width: "333px" }}>{item.content}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.score?.toFixed(1)}</TableText>
                    </Td>
                    <Td>
                      <TableText>{moment(item.createdAt).format("YYYY/MM/DD")}</TableText>
                    </Td>
                    <Td>
                      <TableDelBtn
                        style={{ marginLeft: "6px" }}
                        onClick={() => {
                          setReviewList([...reviewList]);
                          const delList = [item];
                          setDelIdList(delList.map((item) => item.id));
                          setDeleteModalOpen(true);
                        }}
                      />
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          setSelectedItem(item);
                          setDetailModalOpen(true);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.review}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}

      {detailModalOpen ? <ReviewModal data={selectedItem} setModalOpen={setDetailModalOpen} /> : ""}
    </>
  );
};

export default Review;
